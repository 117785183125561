import React from 'react'
import { Link } from 'gatsby'
import { css } from 'styled-components'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FooterIndex from '../../components/footerIndex'
import Quote from '../../components/quote'
import RuthImage from '../../components/ruthImage'
import stepsImage from '../../images/steps.svg'
import focusImage from '../../images/focus.svg'
import { device } from '../../styles/devices'

const TeamRuthPage = () => (
  <Layout>
    <SEO title="Ruth Nießen" />

    <div
      className="grid"
      css={css`
        h3 {
          font-size: 3.2rem;
          margin-bottom: 20px;
        }
        @media ${device.laptop} {
          h3 {
            display: inline-block;
            position: relative;
            background: linear-gradient(0, #fff1c6 24px, transparent 0);
            &:before {
              content: ' ';
              position: absolute;
              bottom: 0;
              left: -18px;
              background-color: transparent;
              border-bottom: 24px solid transparent;
              border-right: 18px solid #fff1c6;
            }
            &:after {
              content: ' ';
              position: absolute;
              bottom: 0;
              background-color: transparent;
              border-top: 24px solid transparent;
              border-left: 18px solid #fff1c6;
            }
          }
        }

        h4 {
          font-family: 'Montserrat';
          font-weight: bold;
        }

        p {
          margin-bottom: 20px;
          line-height: 33.18px;
          letter-spacing: -0.004em;
        }

        ul {
          margin-bottom: 20px;
          list-style: disc;
          line-height: 33.18px;
        }

        li {
          margin-left: 2rem;
        }
      `}
    >
      <div className="grid-contained">
        <div
          css={css`
            & {
              margin-top: 1rem;
              margin-bottom: 3rem;
              font-size: 3rem;
              text-align: center;
            }
            @media ${device.laptop} {
              margin-top: 3rem;
              margin-bottom: 6rem;
              font-size: 4rem;
            }
          `}
        >
          <h2>Ruth Nießen</h2>
        </div>

        <div className="grid-contained">
          <Quote author="Marshall Rosenberg">
            Du kannst Dich jeder Zeit entscheiden, wie Du die Worte Deines
            Gegenübers aufnimmst, die Macht liegt bei Dir.
          </Quote>
        </div>

        <div className="grid-contained">
          <div
            css={css`
              & {
                margin-top: 4rem;
              }
              @media ${device.laptop} {
                display: flex;
                justify-content: space-around;
                margin-top: 6rem;
              }
            `}
          >
            <div>
              <div
                css={css`
                  & {
                    margin-bottom: 4rem;
                    text-align: center;
                  }
                `}
              >
                <RuthImage />
              </div>

              <a
                href="http://ruthniessen.de"
                rel="noopener noreferrer"
                css={css`
                  display: block;
                  text-align: center;
                  margin-top: 20px;
                  color: #3668ed;
                `}
              >
                ruthniessen.de
              </a>
            </div>
            <div
              css={css`
                @media ${device.laptop} {
                  margin-left: 2rem;
                  max-width: 600px;
                }
              `}
            >
              <h3>Konfliktrezept bedeutet für mich</h3>
              <p>
                Jeder Mensch hat sein eigenes Rezept durchs Leben zu gehen! Wir
                bieten individuelle Ansätze zur Bearbeitung Ihrer Konflikte in
                einem geschützten Rahmen, in dem Sie und Ihr Team Ihre passenden
                Lösungen finden können.
              </p>
              <p>
                Als Sozialarbeiterin (BA Soziale Arbeit) und mit meinem
                betriebswirtschaftlichen Masterabschluss (Master of Non-Profit
                Management M.A.) verbinde ich soziale und wirtschaftliche
                Kompetenzen, auf denen ich meine Einschätzung und Mediation
                aufbaue.
              </p>
              <p>
                Ausbrechen aus dem Alltag, neues Erleben und Unbekanntes kennen
                lernen begleitet mich mein Leben lang. Nach dem Abitur lebte ich
                ein Jahr in Burundi, Ostafrika, tauchte in die Kultur und
                Lebensweise der Menschen ein, lernte eine neue Sprache (Kirundi)
                und begriff, dass die bekannte Komfortzone zu verlassen,
                Vertrauen zu sich selbst zu haben und neugierig zu sein, die
                Mittel sind, sich weiterzubilden und Lebenserfahrung zu sammeln.
              </p>
              <p>
                Während meiner fünfjährigen Tätigkeit im Bereich der politischen
                Bildung schulte ich meinen Blick für spezifische
                Herausforderungen im öffentlichen Dienst und im Nonprofit
                Sektor. In einer NGO und mit einem internationalen Team bildete
                sich meine interkulturelle und differenzierte Herangehensweise
                an neue Kunden, Mitarbeitende und Kollegen besonders heraus. Mit
                neugierigem und freundlichem Blick gehe ich jeder
                Herausforderung entgegen und begegne den Menschen offen und
                ehrlich.
              </p>
              <p>
                Mit Franziska Schacht gemeinsam möchte ich unseren Kunden neue
                Anstöße geben oder sie auf ihren Wegen einer Konfliktbewältigung
                begleiten und einen Rahmen schaffen, in dem sie vertrauensvoll
                ihre Lösungen entwickeln können.
              </p>
            </div>
          </div>
        </div>

        <div className="grid-contained">
          <div
            css={css`
              img {
                display: none;
              }

              @media ${device.laptop} {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-around;
                margin-top: 6rem;

                & > img {
                  display: block;
                  margin-left: 2rem;
                  width: 300px;
                  height: 685px;
                }
              }
            `}
          >
            <img src={focusImage} />
            <div
              css={css`
                @media ${device.laptop} {
                  max-width: 600px;
                }
              `}
            >
              <h3>Meine Schwerpunkte</h3>
              <h4>Interkulturelle Zusammenarbeit</h4>
              <p>
                Als Projektmanagerin arbeite ich mit internationalen
                Teilnehmenden aus Marokko, Tunesien, der Türkei und der Ukraine
                zusammen. Die Auseinandersetzung mit kulturellen Unterschieden
                und unterschiedlichen Lebensgeschichten schulte meine
                interkulturelle Kompetenz.
              </p>
              <h4>Projekt-, Prozess- und Changemanagement</h4>
              <p>
                Ich optimierte mehrere Jahre erfolgreich die Prozesse der
                Nonprofit-Organisation, in der ich angestellt war. Besonders
                spannend finde ich die Optimierungen und Veränderungen mit dem
                gesamten Team umzusetzen und Wünsche und Ideen der
                Mitarbeitenden wahrzunehmen und zu integrieren. Soziales
                Feingefühl ist hier gefragt.
              </p>
              <h4>Konfliktmanagement</h4>
              <p>
                Zur Prävention von Konflikten in Teams bieten wir Methoden und
                Strategien, die Sie in Ihrem Unternehmen implementieren können -
                Konfliktmanagement als Arbeitsbereich. Dies bewirkt eine
                langfristige Änderung der Kommunikation und Arbeitsweise in
                Ihren Teams.
              </p>
            </div>
          </div>
        </div>

        <div className="grid-contained">
          <div
            css={css`
              img {
                display: none;
              }
              @media ${device.laptop} {
                display: flex;
                justify-content: space-around;
                margin-top: 6rem;

                & > img {
                  display: block;
                  margin-right: 2rem;
                  width: 300px;
                  height: 497px;
                }
              }
            `}
          >
            <img src={stepsImage} />
            <div>
              <h3>Mein Hintergrund auf einen Blick</h3>
              <h4>Studium</h4>
              <ul>
                <li>Bachelor Soziale Arbeit</li>
                <li>Master Nonprofit-Management und Public Governance</li>
              </ul>

              <h4>Ausbildung</h4>
              <ul>
                <li>Mediatorin</li>
              </ul>

              <h4>Kenntnisse</h4>
              <ul>
                <li>Projektmanagement</li>
                <li>Organisationsentwicklung</li>
                <li>Prozessoptimierung</li>
                <li>Personalmanagement</li>
              </ul>

              <h4>Branche</h4>
              <ul>
                <li>Nonprofit-Sektor</li>
                <li>öffentliche Verwaltung</li>
                <li>Politik</li>
                <li>Erwachsenenbildung</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterIndex />
  </Layout>
)

export default TeamRuthPage
